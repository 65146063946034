// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./controllers"

import './add_jquery'

import('./autocomplete')
import "@rails/request.js"
import './dstv'
import './rewards'
import('./interactions')
import './backend'

