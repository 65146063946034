$(document).ready(function() {
  $("#rewards-list input[type=checkbox], #rewards-list #cash_points").on('change', function () {
    var points_count = 0;
    var points_balance = parseInt($("#rewards-points-balance").data("pointsbalance"));

    $("#rewards-list input.reward-selector[type=checkbox]").each(function(index,item){
      if ($(item).is(":checked")) {
        points_count = points_count + parseInt($(item).data("points"))
        if ($(item).attr("id") == 'cash-prize-option') {
          points_count = points_count + parseInt($("#rewards-list #cash_points").val());
        }
      }
    });

    var current_cash_points = parseInt($("#rewards-list #cash_points").val());
    var max_points = parseInt($("#rewards-list #cash_points").attr('max'));
    
    if (current_cash_points > max_points) {
      current_cash_points = max_points;
      $("#rewards-list #cash_points").val(max_points);
    }
    
    $("#cash-points-display").html(current_cash_points.toLocaleString());
    $("#cash-points-amount").html((current_cash_points * 5).toLocaleString());

    $('#rewards-form').on('keyup keypress', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });

    var points_left = points_balance - points_count;
    if (points_left < 0) {
      points_left = 0;
      $("#too-much").show();
      $("#redeem-away").hide();
    } else {
      $("#too-much").hide();
      $("#redeem-away").show();
    }
    
    $("#rewards-points-left").html(points_left.toLocaleString());
    $("#rewards-items-points").html(points_count.toLocaleString());
  });
});
