import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="location"
export default class extends Controller {
  update_state(event) {
    let region_id = event.target.selectedOptions[0].value
  
    get(`/regions/${region_id}/states`,  {
      responseKind: "turbo-stream"
    })
  }

  update_lga(event) {
    let state_id = event.target.selectedOptions[0].value
  
    get(`/nigerian_states/${state_id}/lgas`,  {
      responseKind: "turbo-stream"
    })
  }

  update_ward(event) {
    let lga_id = event.target.selectedOptions[0].value
  
    get(`/lgas/${lga_id}/wards`,  {
      responseKind: "turbo-stream"
    })
  }
}
