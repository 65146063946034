$(document).ready(function() {
  $('#user_mcn_report_name, #user_upline_user_name').autocomplete({
    serviceUrl: '/dealers/suggestions',
    dataType: 'json',
    minChars: 2,
    noCache: true,
    onSelect: function (suggestion) {
      let target_id = this.id.replace("_name", "_id");
      $(`#${target_id}`).val(suggestion.data);
    }
  });
});
